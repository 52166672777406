<template>
    <div class="contentSeven">
        <img src="http://iv.okvu.cn/vugw/img/img2.jpg" alt="" class="csBg" />
        <div class="ctSvenBox">
            <!-- 标题 -->
            <div class="ctStitle">智能前端</div>
            <!-- 5个盒子 -->
            <div class="ctSList">
                <ul>
                    <li class="ctSli wow animate__animated animate__fadeInUp">
                        <div class="ctsBg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <!-- 图标 -->
                        <div class="ctSIcon">
                            <img class="ctSimg" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <div class="ctSText">
                            <p class="ctSTop">HTML5/CSS3</p>
                            <p class="ctSBottom">新一代技术语言，更高效，视觉更丰富</p>
                        </div>
                    </li>
                    <li class="ctSli wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
                        <div class="ctsBg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <!-- 图标 -->
                        <div class="ctSIcon2">
                            <img class="ctSimg" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <div class="ctSText">
                            <p class="ctSTop">响应式</p>
                            <p class="ctSBottom">可同时兼容PC、平板、手机等各种设备显示</p>
                        </div>
                    </li>
                    <li class="ctSli wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                        <div class="ctsBg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <!-- 图标 -->
                        <div class="ctSIcon3">
                            <img class="ctSimg" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <div class="ctSText">
                            <p class="ctSTop">异步请求</p>
                            <p class="ctSBottom">多线程处理请求，提供更人性化体验</p>
                        </div>
                    </li>
                    <li class="ctSli wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                        <div class="ctsBg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <!-- 图标 -->
                        <div class="ctSIcon4">
                            <img class="ctSimg" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <div class="ctSText">
                            <p class="ctSTop">按需加载</p>
                            <p class="ctSBottom">根据用户操作，分步请求网络资源，提高网站响应效率</p>
                        </div>
                    </li>
                    <li class="ctSli wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">
                        <div class="ctsBg">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <!-- 图标 -->
                        <div class="ctSIcon5">
                            <img class="ctSimg" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <div class="ctSText">
                            <p class="ctSTop">模块化开发</p>
                            <p class="ctSBottom">减少系统多余耦合，提升代码可维护性</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "contentSeven",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.contentSeven {
    width: 100%;
    height: 900px;
    position: relative;
    overflow: hidden;
    .csBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }
    .ctSvenBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        margin-top: 50px;
        // 标题
        .ctStitle {
            width: 30%;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
            text-align: center;
            font-weight: 900;
            margin: 0 auto;
            color: white;
            position: relative;
        }
        // 5个盒子
        .ctSList {
            width: 80%;
            height: 750px;
            margin: 0 auto;
            margin-top: 40px;
            ul {
                width: 100%;
                height: 100%;
                position: relative;
                .ctSli {
                    width: 320px;
                    height: 370px;
                    position: absolute;
                    top: 0;
                    left: 0.5%;
                    overflow: hidden;
                    .ctsBg {
                        position: absolute;
                        bottom: -810px;
                        left: -605px;
                    }
                    // 图标
                    .ctSIcon,
                    .ctSIcon2,
                    .ctSIcon3,
                    .ctSIcon4,
                    .ctSIcon5 {
                        width: 70px;
                        height: 100px;
                        margin: 0 auto;
                        position: relative;
                        overflow: hidden;
                        transition: 0.5s;
                        .ctSimg {
                            position: absolute;
                            right: -344px;
                            padding-top: 20px;
                        }
                    }
                    .ctSIcon2 {
                        width: 82px;
                        .ctSimg {
                            position: absolute;
                            right: -255px;
                            padding-top: 20px;
                        }
                    }
                    .ctSIcon3 {
                        width: 82px;
                        .ctSimg {
                            position: absolute;
                            right: -170px;
                            padding-top: 20px;
                        }
                    }
                    .ctSIcon4 {
                        width: 79px;
                        .ctSimg {
                            position: absolute;
                            right: -94px;
                            padding-top: 20px;
                        }
                    }
                    .ctSIcon5 {
                        width: 73px;
                        .ctSimg {
                            position: absolute;
                            right: -20px;
                            padding-top: 20px;
                        }
                    }
                    .ctSText {
                        width: 70%;
                        height: 200px;
                        margin: 0 auto;
                        text-align: center;
                        position: relative;
                        .ctSTop {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            font-size: 20px;
                            color: white;
                            font-weight: 900;
                        }
                        .ctSBottom {
                            width: 90%;
                            height: 150px;
                            line-height: 50px;
                            margin: 0 auto;
                            color: white;
                        }
                    }
                }
                .ctSli:hover {
                    .ctSIcon,
                    .ctSIcon2,
                    .ctSIcon3,
                    .ctSIcon4,
                    .ctSIcon5 {
                        transform: rotateY(180deg);
                    }
                }
                .ctSli:nth-child(2) {
                    position: absolute;
                    top: 0;
                    left: 35%;
                }
                .ctSli:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 69%;
                }
                .ctSli:nth-child(4) {
                    position: absolute;
                    top: 40%;
                    left: 18%;
                }
                .ctSli:nth-child(5) {
                    position: absolute;
                    top: 40%;
                    left: 52%;
                }
            }
        }
    }
}
</style>
