<template>
    <div class="contentFive">
        <img src="http://iv.okvu.cn/vugw/img/img0.jpg" alt="" class="cfBg" />
        <div class="ctFox">
            <!-- 左边文字 -->
            <div class="ctFleftText">
                <div class="ctFBig">威有超级战车系统</div>
                <div class="ctFSmall">
                    7年来，威有紧随潮流自主研发了众多产品，在研发新产品、定制各类网站项目的技术沉淀下，于2019年终于将其PAAS化，逐步共享给业界人士使用。威有超级站车系统，大大促进创意策划、定制设计、前端技术、程序开发、图文制作等各角色统一协调、信息对称的技术合作，解决了网站前期策划、建设过程进度管理、上线后技术维护的完整问题。
                </div>
            </div>
        </div>

        <!-- 右边小文字 -->
        <div class="ctFRightText">
            <ul>
                <li class="ctFLi">创意策划</li>
                <li class="ctFLi">定制开发</li>
                <li class="ctFLi">图文制作</li>
                <li class="ctFLi">程序开发</li>
                <li class="ctFLi">前端技术</li>
                <li class="ctFLi wow animate__animated animate__fadeIn">
                    <img src="https://iv.okvu.cn/vugw/img/img0-1.png" alt="" />
                </li>
                <li class="ctFLi wow animate__animated animate__fadeIn">
                    <img src="https://iv.okvu.cn/vugw/img/img0-2.png" alt="" />
                </li>
                <li class="ctFLi wow animate__animated animate__fadeIn">
                    <img src="https://iv.okvu.cn/vugw/img/img0-3.png" alt="" />
                </li>
                <li class="ctFLi wow animate__animated animate__fadeIn">
                    <img src="https://iv.okvu.cn/vugw/img/img0-4.png" alt="" />
                </li>
                <li class="ctFLi wow animate__animated animate__fadeIn">
                    <img src="https://iv.okvu.cn/vugw/img/img0-5.png" alt="" />
                </li>
                <li class="ctFLi wow animate__animated animate__fadeIn">
                    <img src="https://iv.okvu.cn/vugw/img/img0-6.png" alt="" />
                </li>
                <li class="ctFLi wow animate__animated animate__fadeIn">
                    <img src="https://iv.okvu.cn/vugw/img/img0-7.png" alt="" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentFive",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.contentFive {
    width: 100%;
    // height: 600px;
    margin-top: 5px;
    position: relative;
    overflow: hidden;
    .cfBg {
        // height: 100%;
        width: 100%;
        // position: absolute;
        // top: 0;
        // left: -15%;
    }
    .ctFox {
        width: 100%;
        max-width: 1440px;
        height: 100%;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        position: absolute;
        // 左边文字
        .ctFleftText {
            width: 50%;
            padding: 105px 100px 0;
            // height: 50%;
            // position: absolute;
            // top: 50%;
            // left: 6%;
            // transform: translateY(-50%);
            // float: left;
            color: white;
            .ctFBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 46px;
                font-weight: 900;
            }
            .ctFSmall {
                width: 100%;
                height: 100px;
                font-size: 14px;
                line-height: 25px;
                margin-top: 50px;
            }
        }
    }
    // 右边小文字
    .ctFRightText {
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
        color: white;
        .ctFLi {
            width: 100px;
            height: 20px;
            line-height: 20px;
            text-align: center;
        }
        .ctFLi:nth-child(1) {
            position: absolute;
            top: 15%;
            left: 54%;
        }
        .ctFLi:nth-child(2) {
            position: absolute;
            top: 32%;
            left: 53%;
        }
        .ctFLi:nth-child(3) {
            position: absolute;
            top: 45%;
            left: 51%;
        }
        .ctFLi:nth-child(4) {
            position: absolute;
            top: 17%;
            right: 27%;
        }
        .ctFLi:nth-child(5) {
            position: absolute;
            top: 23%;
            right: 21%;
        }
        .ctFLi:nth-child(6) {
            position: absolute;
            top: 46%;
            left: 23%;
        }
        .ctFLi:nth-child(7) {
            position: absolute;
            top: 40%;
            left: 39%;
        }
        .ctFLi:nth-child(8) {
            position: absolute;
            top: 39%;
            right: 14%;
        }
        .ctFLi:nth-child(9) {
            position: absolute;
            top: 24%;
            right: 22%;
        }
        .ctFLi:nth-child(10) {
            position: absolute;
            top: 22%;
            right: 36%;
        }
        .ctFLi:nth-child(11) {
            position: absolute;
            top: 10%;
            left: 31%;
        }
        .ctFLi:nth-child(12) {
            position: absolute;
            top: 32%;
            left: 30%;
        }
    }
}
</style>
