<template>
    <!-- 服务保障-安全 -->
    <div class="tsSecurity">
        <!-- 内容五 -->
        <ContentFive></ContentFive>
        <!-- 内容六 -->
        <ContentSix></ContentSix>
        <!-- 内容七 -->
        <ContentSeven></ContentSeven>
        <!-- 内容八 -->
        <ContentEight></ContentEight>
        <!-- 内容九 -->
        <ContentNine></ContentNine>
    </div>
</template>

<script>
import ContentFive from "./ContentFive.vue";
import ContentSix from "./ContentSix.vue";
import ContentSeven from "./ContentSeven.vue";
import ContentEight from "./ContentEight.vue";
import ContentNine from "./ContentNine.vue";

export default {
    name: "TsSecurity",
    components: {
        ContentFive,
        ContentSix,
        ContentSeven,
        ContentEight,
        ContentNine,
    },
};
</script>

<style lang="less" scoped>
.tsSecurity {
    min-width: 1423px;
    margin: 0 auto;
    text-align: left;
}
</style>
